import NextI18Next from 'next-i18next';

const nextI18next = new NextI18Next({
    browserLanguageDetection: false,
    serverLanguageDetection: false,
    defaultLanguage: 'fr',
    otherLanguages: ['en', 'pt'],
    localeSubpaths: { fr: 'fr', en: 'en', pt: 'pt' },
    localePath: 'public/static/locales',
    ignoreRoutes: ['/_next', '/static', '/api', '/geoip'],
    debug: false, // process.env.NODE_ENV !== 'production' && typeof window === 'object',
});

const { i18n, Link, Trans, withTranslation, appWithTranslation, Router } = nextI18next;

export { withTranslation, i18n, Link, Trans, appWithTranslation, Router };
export default nextI18next;
