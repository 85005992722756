import { Action } from 'redux';
import { UIActionType } from './UIActionType';
import { CartEntry } from '../../domain/commerce';
import { AccessibilityState } from './UISelectors';

export interface UIAction extends Action<UIActionType> {
    payload?: string;
}

export type SetAccessibilityOptionsAction = UIAction & { options: AccessibilityState };

export function createToggleAnimationsAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_ANIMATIONS,
    };
}

export function createToggleContrastsAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_CONTRASTS,
    };
}

export function createToggleMenuMobileAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_MENU_MOBILE,
    };
}

export function createToggleSearchModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_SEARCH_MODAL,
    };
}

export function createToggleConnectionModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_CONNECTION_MODAL,
    };
}

export function createToggleOrderModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_ORDER_MODAL,
    };
}

export function createToggleMiniCartAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_MINI_CART,
    };
}

export function createToggleSuccessfullyAddedToCartModalAction(item?: CartEntry): UIAction & { item?: CartEntry } {
    return {
        type: UIActionType.TOGGLE_ADDED_TO_CART_MODAL,
        item,
    };
}

export function createToggleAlreadyInCartErrorModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_ALREADY_IN_CART_ERROR_MODAL,
    };
}

export function createToggleOrderedRecentlyErrorModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_ORDERED_RECENTLY_ERROR_MODAL,
    };
}

export function createToggleNotEnoughCreditsErrorModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_NOT_ENOUGH_CREDITS_ERROR_MODAL,
    };
}

export function createToggleCreditsRequestModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_CREDITS_REQUEST_MODAL,
    };
}

export function createToggleReactivateAccountModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_REACTIVATE_ACCOUNT_MODAL,
    };
}

export function createSetAccessibilityOptions(options: AccessibilityState): SetAccessibilityOptionsAction {
    return { type: UIActionType.SET_ACCESSIBILITY_OPTIONS, options };
}

export function createToggleAddReviewModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_ADD_REVIEW_MODAL,
    };
}

export function createToggleReviewModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_REVIEW_MODAL,
    };
}

export function createToggleConfirmReviewDeletionModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_REVIEW_DELETION_CONFIRMATION_MODAL,
    };
}

export function createDisplayGenericErrorMessageAction(message: string): UIAction {
    return {
        type: UIActionType.DISPLAY_GENERIC_ERROR_MESSAGE,
        payload: message,
    };
}

export function createToggleGenericErrorModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_GENERIC_ERROR_MODAL,
    };
}

export function createToggleDigitalOrderModalAction(): UIAction {
    return {
        type: UIActionType.TOGGLE_DIGITAL_ORDER_MODAL,
    };
}
