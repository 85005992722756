export enum UIActionType {
    TOGGLE_ANIMATIONS = 'UI/TOGGLE_ANIMATIONS',
    TOGGLE_CONTRASTS = 'UI/TOGGLE_CONTRASTS',
    SET_ACCESSIBILITY_OPTIONS = 'UI/SET_ACCESSIBILITY_OPTIONS',

    TOGGLE_MENU_MOBILE = 'UI/TOGGLE_MENU_MOBILE',
    TOGGLE_SEARCH_MODAL = 'UI/TOGGLE_SEARCH_MODAL',
    TOGGLE_CONNECTION_MODAL = 'UI/TOGGLE_CONNECTION_MODAL',
    TOGGLE_ORDER_MODAL = 'UI/TOGGLE_ORDER_MODAL',
    TOGGLE_MINI_CART = 'UI/TOGGLE_MINI_CART',
    TOGGLE_ADDED_TO_CART_MODAL = 'UI/TOGGLE_ADDED_TO_CART_MODAL',
    TOGGLE_ALREADY_IN_CART_ERROR_MODAL = 'UI/TOGGLE_ADD_TO_CART_ERROR_MODAL',
    TOGGLE_ORDERED_RECENTLY_ERROR_MODAL = 'UI/TOGGLE_ORDERED_RECENTLY_ERROR_MODAL',
    TOGGLE_NOT_ENOUGH_CREDITS_ERROR_MODAL = 'UI/TOGGLE_NOT_ENOUGH_CREDITS_ERROR_MODAL',
    TOGGLE_CREDITS_REQUEST_MODAL = 'UI/TOGGLE_CREDITS_REQUEST_MODAL',
    TOGGLE_MEMBER_ACCOUNT_UPDATE_MODAL = 'UI/TOGGLE_MEMBER_ACCOUNT_UPDATE_MODAL',
    TOGGLE_REACTIVATE_ACCOUNT_MODAL = 'UI/TOGGLE_REACTIVATE_ACCOUNT_MODAL',
    TOGGLE_ADD_REVIEW_MODAL = 'UI/TOGGLE_ADD_REVIEW_MODAL',
    TOGGLE_REVIEW_MODAL = 'UI/TOGGLE_REVIEW_MODAL',
    TOGGLE_REVIEW_DELETION_CONFIRMATION_MODAL = 'UI/TOGGLE_REVIEW_DELETION_CONFIRMATION_MODAL',

    TOGGLE_GENERIC_ERROR_MODAL = 'UI/TOGGLE_GENERIC_ERROR_MODAL',
    DISPLAY_GENERIC_ERROR_MESSAGE = 'UI/DISPLAY_GENERIC_ERROR_MESSAGE',

    TOGGLE_DIGITAL_ORDER_MODAL = 'UI/TOGGLE_DIGITAL_ORDER_MODAL',
}
