import { UIActionType } from './UIActionType';
import { UIAction, SetAccessibilityOptionsAction } from './UIActions';

export interface UIState {
    animations: boolean;
    contrasts: boolean;
    menuMobileIsOpen: boolean;
    searchModalIsOpen: boolean;
    connectionModalIsOpen: boolean;
    orderModalIsOpen: boolean;
    miniCartIsOpen: boolean;
    addedToCartModalIsOpen: boolean;
    alreadyInCartErrorModalIsOpen: boolean;
    notEnoughCreditErrorModalIsOpen: boolean;
    orderedRecentlyErrorModalIsOpen: boolean;
    creditsRequestModalIsOpen: boolean;
    reactivateAccountModalIsOpen: boolean;
    addReviewModalIsOpen: boolean;
    reviewModalIsOpen: boolean;
    reviewDeletionConfirmationModalIsOpen: boolean;
    genericErrorMessage: string;
    genericErrorModalIsOpen: boolean;

    digitalOrderModalIsOpen: boolean;
}

export const initialState: UIState = {
    animations: true,
    contrasts: false,
    menuMobileIsOpen: false,
    searchModalIsOpen: false,
    connectionModalIsOpen: false,
    orderModalIsOpen: false,
    miniCartIsOpen: false,
    addedToCartModalIsOpen: false,
    orderedRecentlyErrorModalIsOpen: false,
    alreadyInCartErrorModalIsOpen: false,
    notEnoughCreditErrorModalIsOpen: false,
    creditsRequestModalIsOpen: false,
    reactivateAccountModalIsOpen: false,
    addReviewModalIsOpen: false,
    reviewModalIsOpen: false,
    reviewDeletionConfirmationModalIsOpen: false,
    genericErrorMessage: '',
    genericErrorModalIsOpen: false,

    digitalOrderModalIsOpen: false,
};

export const UIReducer = (state: UIState = initialState, action: UIAction): UIState => {
    switch (action.type) {
        case UIActionType.TOGGLE_ANIMATIONS:
            return {
                ...state,
                ...{ animations: !state.animations },
            };

        case UIActionType.TOGGLE_CONTRASTS:
            return {
                ...state,
                ...{ contrasts: !state.contrasts },
            };

        case UIActionType.TOGGLE_MENU_MOBILE:
            return {
                ...state,
                ...{ menuMobileIsOpen: !state.menuMobileIsOpen },
            };

        case UIActionType.TOGGLE_SEARCH_MODAL:
            return {
                ...state,
                ...{ searchModalIsOpen: !state.searchModalIsOpen },
            };

        case UIActionType.TOGGLE_CONNECTION_MODAL:
            return {
                ...state,
                ...{ connectionModalIsOpen: !state.connectionModalIsOpen },
            };

        case UIActionType.TOGGLE_ORDER_MODAL:
            return {
                ...state,
                ...{ orderModalIsOpen: !state.orderModalIsOpen },
            };

        case UIActionType.TOGGLE_MINI_CART:
            return {
                ...state,
                ...{ miniCartIsOpen: !state.miniCartIsOpen },
            };

        case UIActionType.TOGGLE_ADDED_TO_CART_MODAL:
            return {
                ...state,
                ...{ addedToCartModalIsOpen: !state.addedToCartModalIsOpen },
            };

        case UIActionType.TOGGLE_ALREADY_IN_CART_ERROR_MODAL:
            return {
                ...state,
                ...{ alreadyInCartErrorModalIsOpen: !state.alreadyInCartErrorModalIsOpen },
            };

        case UIActionType.TOGGLE_ORDERED_RECENTLY_ERROR_MODAL:
            return {
                ...state,
                ...{ orderedRecentlyErrorModalIsOpen: !state.orderedRecentlyErrorModalIsOpen },
            };

        case UIActionType.TOGGLE_NOT_ENOUGH_CREDITS_ERROR_MODAL:
            return {
                ...state,
                ...{ notEnoughCreditErrorModalIsOpen: !state.notEnoughCreditErrorModalIsOpen },
            };

        case UIActionType.TOGGLE_CREDITS_REQUEST_MODAL:
            return {
                ...state,
                ...{ creditsRequestModalIsOpen: !state.creditsRequestModalIsOpen },
            };

        case UIActionType.TOGGLE_REACTIVATE_ACCOUNT_MODAL:
            return {
                ...state,
                ...{ reactivateAccountModalIsOpen: !state.reactivateAccountModalIsOpen },
            };

        case UIActionType.SET_ACCESSIBILITY_OPTIONS:
            return {
                ...state,
                ...(action as SetAccessibilityOptionsAction).options,
            };

        case UIActionType.TOGGLE_ADD_REVIEW_MODAL:
            return {
                ...state,
                ...{ addReviewModalIsOpen: !state.addReviewModalIsOpen },
            };

        case UIActionType.TOGGLE_REVIEW_MODAL:
            return {
                ...state,
                ...{ addReviewModalIsOpen: !state.addReviewModalIsOpen },
            };

        case UIActionType.TOGGLE_REVIEW_DELETION_CONFIRMATION_MODAL:
            return {
                ...state,
                ...{ reviewDeletionConfirmationModalIsOpen: !state.reviewDeletionConfirmationModalIsOpen },
            };

        case UIActionType.TOGGLE_REVIEW_DELETION_CONFIRMATION_MODAL:
            return {
                ...state,
                ...{ reviewDeletionConfirmationModalIsOpen: !state.reviewDeletionConfirmationModalIsOpen },
            };

        case UIActionType.TOGGLE_GENERIC_ERROR_MODAL:
            return {
                ...state,
                ...{ genericErrorModalIsOpen: !state.genericErrorModalIsOpen },
            };

        case UIActionType.DISPLAY_GENERIC_ERROR_MESSAGE:
            return {
                ...state,
                ...{ genericErrorMessage: action.payload!, genericErrorModalIsOpen: true },
            };

        case UIActionType.TOGGLE_DIGITAL_ORDER_MODAL:
            return {
                ...state,
                ...{ digitalOrderModalIsOpen: !state.digitalOrderModalIsOpen },
            };

        default:
            return state;
    }
};
